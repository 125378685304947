import React from 'react';

import PageHeader from 'components/common/PageHeader';

import styles from './About.module.css';

interface IAboutProps {
  html: string;
}

export const About: React.FC<IAboutProps> = ({ html }) => (
  <>
    <PageHeader title="About" />
    <main>
      <div className={styles.pageContainer}>
        <div className={styles.pageInnerContainer}>
          <div
            className={styles.textContainer}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </main>
  </>
);
