import React from 'react';
import { graphql } from 'gatsby';
import { ABOUT_SEO } from '../utils/seo';

import Layout from 'components/layout';
import { About } from 'components/About';

import { GetAboutQuery } from 'types';

interface IAboutPageProps {
  data: GetAboutQuery;
  location: Location;
}

const AboutPage: React.FC<IAboutPageProps> = ({
  data: {
    copy: {
      text: {
        childMarkdownRemark: { excerpt, html },
      },
    },
  },
}) => (
  <Layout
    title={ABOUT_SEO.title}
    description={ABOUT_SEO.description(excerpt)}
    pathname={ABOUT_SEO.pathname}
  >
    <About html={html} />
  </Layout>
);

export default AboutPage;

export const aboutQuery = graphql`
  query GetAbout {
    copy: contentfulCopy(placement: { eq: "About Page" }) {
      text {
        childMarkdownRemark {
          excerpt
          html
        }
      }
    }
  }
`;
